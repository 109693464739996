import React, { Suspense, useEffect } from "react";
import "./App.css";
import { Toaster } from 'react-hot-toast';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import TagManager from "react-gtm-module";
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import Header from "./Components/Header/Header.jsx";
import Footer from "./Components/Footer/Footer.jsx";
import MobileNav from "./Components/MobileNav/MobileNav.jsx";
import Spinner from "./Components/Spinner/Spinner.jsx";
import { useSelector } from "react-redux";
import ClientTaskForm from "./Pages/ClientTaskForm/ClientTaskForm.jsx";
import BroadcastTask from "./Pages/BroadcastTask/BroadcastTask.jsx";

// Lazy loading
const SignUp = React.lazy(() => import('./Pages/SignUp/SignUp.jsx'));
const SignIn = React.lazy(() => import('./Pages/SignIn/SignIn.jsx'));
const ForgotPassword = React.lazy(() => import('./Pages/ForgotPassword/ForgotPassword.jsx'));
const ResetPassword = React.lazy(() => import('./Pages/ResetPassword/ResetPassword.jsx'));
const VerifyVerificationCode = React.lazy(() => import('./Pages/VerifyVerificationCode/VerifyVerificationCode.jsx'));
const Policy = React.lazy(() => import('./Pages/Accessibility/Accessibility.jsx'));
const Accessibility = React.lazy(() => import('./Pages/PrivacyPolicy/PrivacyPolicy.jsx'));
const ContactUs = React.lazy(() => import('./Pages/ContactUs/ContactUs.jsx'));
const VerifyWorkerEmail = React.lazy(() => import('./Pages/VerifyWorkerEmail/VerifyWorkerEmail.js'));
const FriendsNetwork = React.lazy(() => import('./Pages/FriendsNetwork/FriendsNetwork.jsx'));
const VerifyWorkerByFriend = React.lazy(() => import('./Pages/VerifyWorkerByFriend/VerifyWorkerByFriend.jsx'));
const BecomeAWorker = React.lazy(() => import('./Pages/BecomeAWorker/BecomeAWorker.jsx'));
const AddAWorker = React.lazy(() => import('./Pages/AddAWorker/AddAWorker.jsx'));
const RegisterMultipleWorkers = React.lazy(() => import('./Pages/RegisterMultipleWorkers/RegisterMultipleWorkers.jsx'));
const UpdateProfile = React.lazy(() => import('./Pages/UpdateProfile/UpdateProfile.jsx'));
const NotAuthorized = React.lazy(() => import('./Pages/NotAuthorized/NotAuthorized.jsx'));
const Home = React.lazy(() => import('./Pages/Home/Home.jsx'));
const About = React.lazy(() => import('./Pages/About/About.jsx'));
const Skills = React.lazy(() => import('./Pages/Skills/Skills.jsx'));
const Profile = React.lazy(() => import('./Pages/Profile/Profile.jsx'));
const Worker = React.lazy(() => import('./Pages/Worker/Worker.jsx'));
const WorkersPerSkill = React.lazy(() => import('./Pages/WorkersPerSkill/WorkersPerSkill.jsx'));
const WorkersPerSkillLocation = React.lazy(() => import('./Pages/WorkerPerSkillLocation/WorkerPerSkillLocation.jsx'));
const Dashboard = React.lazy(() => import("./Pages/Dashboard/Dashboard.jsx"));
const AddASkill = React.lazy(() => import("./Components/AdminSidebarComponents/AddASkill/AddASkill.jsx"));
const DeleteASkill = React.lazy(() => import("./Components/AdminSidebarComponents/DeleteASkill/DeleteASkill.jsx"));
const DeleteAWorker = React.lazy(() => import("./Components/AdminSidebarComponents/DeleteAWorker/DeleteAWorker.jsx"));
const AskUserInfo = React.lazy(() => import("./Pages/AskUserInfo/AskUserInfo.jsx"));
const CheckoutForm = React.lazy(() => import("./Pages/CheckoutForm/CheckoutForm.jsx"));
const RecommendSkill = React.lazy(() => import("./Pages/RecommendSkill/RecommendSkill.jsx"));
const Bookings = React.lazy(() => import("./Pages/Bookings/Bookings.jsx"));
const AvailableBookings = React.lazy(() => import("./Components/BookingsSidebarComponents/AvailableBookings/AvailableBookings.jsx"));
const EmployerBookings = React.lazy(() => import("./Components/BookingsSidebarComponents/EmployerBookings/EmployerBookings.jsx"));
const AllPaymentMethods = React.lazy(() => import("./Pages/AllPaymentMethods/AllPaymentMethods.jsx"));
const WorkerTransferMoney = React.lazy(() => import("./Pages/WorkerTransferMoney/WorkerTransferMoney.jsx"));

//Tracking website not related to Fori Mazdoori
const TrackingFori = React.lazy(() => import('./Pages/TrackingFori/TrackingFori.jsx'));

// Stripe
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function App() {
   const { user } = useSelector((store) => store.auth);
   useEffect(() => {
      TagManager.initialize({ gtmId: process.env.REACT_APP_GOOGLE_Tag_MANAGER_KEY });
   }, [])

   return (
      <div className="App">
         <Router>
            <Header />
            <MobileNav />
            <Elements stripe={stripePromise}>
               <Suspense fallback={<div><Spinner /></div>}>
                  <Routes>
                     <Route path="/" element={<Home />} />
                     <Route path="/about" element={<About />} />
                     <Route path="/skills" element={<Skills />} />
                     {/* <Route path="/profile" element={<Profile />} /> */}
                     <Route path="/worker/:id" element={<Worker />} />
                     <Route path="/skill/:jobTitle" element={<WorkersPerSkill />} />
                     <Route path="/workerPerSkillLocation/:location" element={<WorkersPerSkillLocation />} />
                     <Route path="/signup" element={<SignUp />} />
                     <Route path="/signin" element={<SignIn />} />
                     <Route path="/forgot-password" element={<ForgotPassword />} />
                     <Route path="/reset-password/:token" element={<ResetPassword />} />
                     <Route path="/verificationCode/:token" element={<VerifyVerificationCode />} />
                     <Route path="/register-as-worker" element={<BecomeAWorker />} />
                     <Route path="/addAWorker" element={<AddAWorker />} />
                     <Route path="/register-multiple-workers" element={<RegisterMultipleWorkers />} />
                     <Route path="/friends-network/:id" element={<FriendsNetwork />} />
                     <Route path="/UpdateProfile" element={<UpdateProfile />} />
                     <Route path="/verifyWorkerByFriend/:id" element={<VerifyWorkerByFriend />} />
                     <Route path="/verifyEmail/:token" element={<VerifyWorkerEmail />} />
                     <Route path="/policy" element={<Policy />} />
                     <Route path="/accessibility" element={<Accessibility />} />
                     <Route path="/contact" element={<ContactUs />} />
                     <Route path="/recommendSkill" element={<RecommendSkill />} />
                     <Route path="/NotAuthorized" element={<NotAuthorized />} />


                     <Route path="/allPaymentMethods" element={<AllPaymentMethods />} />
                     <Route path="/workerTransferMoney" element={<WorkerTransferMoney />} />
                     <Route path="/savePaymentMethod" element={<CheckoutForm />} />

                     <Route path="/bookings" element={<Bookings />} >
                        <Route index element={<EmployerBookings />} />
                        <Route path="availableBookings" element={<AvailableBookings />} />
                        <Route path="bookingsAsEmployer" element={<EmployerBookings />} />
                        <Route path="profile" element={<Profile />} />
                        <Route path="workerTransferMoney" element={<WorkerTransferMoney />} />
                        <Route path="createBooking/:skill?" element={<AskUserInfo />} />
                        <Route path="createBroadcastTask" element={<ClientTaskForm />} />
                        <Route path="broadcastTask" element={<BroadcastTask />} />
                        <Route path="allPaymentMethods" element={<AllPaymentMethods />} />
                     </Route>

                     {user?.email === "ceo@fori.co" ? (
                        <Route path="/dashboard/*" element={<Dashboard />}>
                           <Route index element={<AddASkill />} />
                           <Route path="addASkill" element={<AddASkill />} />
                           <Route path="deleteASkill" element={<DeleteASkill />} />
                           <Route path="deleteAWorker" element={<DeleteAWorker />} />
                        </Route>
                     ) : (
                        <Route path="/dashboard/*" element={<NotAuthorized />} />
                     )}
                     {/* This is for tracking website not related to Fori Mazdoori */}
                     <Route path="/trackingFori/:id" element={<TrackingFori />} />
                  </Routes>
               </Suspense>
            </Elements>
            <Footer />
         </Router>
         <Toaster />
      </div>
   );
}

export default App;
