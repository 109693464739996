import React, { useEffect, useState } from 'react';
import { Send } from 'lucide-react';
import { getAllSkills } from '../../features/AdminSlice';
import Select from "react-select";
import './ClientTaskForm.css';
import "../BecomeAWorker/BecomeAWorker.css";
import "../SignIn/SignIn.css";
import { useDispatch, useSelector } from 'react-redux';
import { otherIcons } from '../../Assets/Data/otherIcons';
import { FaLocationCrosshairs } from 'react-icons/fa6';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { createBroadcastJob } from '../../features/Booking';
import { useNavigate } from 'react-router-dom';

const ClientTaskForm = () => {
   const dispatch = useDispatch();
   const { allSkills } = useSelector((store) => store.admin);
   const navigate = useNavigate();
   useEffect(() => {
      dispatch(getAllSkills());
   }, [dispatch]);

   const [formData, setFormData] = useState({
      Name: '',
      taskDescription: '',
      location: '',
      addressCoordinates: null,
      estimatedTime: '',
      requiredSkills: '',
      startDate: '',
      startTime: '',
      budget: '',
   });

   const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData(prevState => ({
         ...prevState,
         [name]: value
      }));
   };

   const handleLocationChange = async (value) => {
      if (value.trim() === '') return;

      try {
         const response = await axios.get(`https://api.opencagedata.com/geocode/v1/json?q=${encodeURIComponent(value)}&key=${process.env.REACT_APP_OPENCAGE_API_KEY}`);
         if (response.data.results && response.data.results.length > 0) {
            const { lat, lng } = response.data.results[0].geometry;
            setFormData(prevState => ({
               ...prevState,
               addressCoordinates: { lat, lng }
            }));
            toast.success("Location coordinates fetched successfully");
         } else {
            toast.error("No results found for the entered location");
         }
      } catch (error) {
         console.error('Error fetching coordinates:', error);
         toast.error("Error fetching location coordinates");
      }
   };

   const handleNumericChange = (e) => {
      const { name, value } = e.target;
      const numericValue = value.replace(/[^0-9.]/g, '');
      setFormData(prevState => ({
         ...prevState,
         [name]: numericValue
      }));
   };

   const handleSelectChange = (selectedOption) => {
      setFormData(prevState => ({
         ...prevState,
         requiredSkills: selectedOption
      }));
   };

   const handleSubmit = async (e) => {
      e.preventDefault();
      const { requiredSkills, location, taskDescription, startTime, estimatedTime, addressCoordinates, startDate, budget } = formData;
      const taskData = {
         taskName: requiredSkills.value,
         taskLocation: {
            ...addressCoordinates,
            textAddress: location
         },
         taskDescription,
         startTime: `${startDate}T${startTime}`,
         estimatedTime,
         budget
      };
      try {
         const response = await dispatch(createBroadcastJob(taskData));
         if (response.error) {
            toast.error("Error creating broadcast job");
         } else {
            toast.success("Broadcast job created successfully");
            setFormData({
               Name: '',
               location: '',
               taskDescription: '',
               startDate: '',
               startTime: '',
               estimatedTime: '',
               budget: '',
               addressCoordinates: { lat: null, lng: null },
               requiredSkills: ''
            });
            navigate("/Bookings/broadcastTask");

         }
      } catch (error) {
         console.error('Error submitting form:', error);
         toast.error("Error creating broadcast job");
      }
   };

   const handleLocationClick = () => {
      if (navigator.geolocation) {
         navigator.geolocation.getCurrentPosition(
            async (position) => {
               const { latitude, longitude } = position.coords;
               const coordinates = { lat: latitude, lng: longitude };

               try {
                  const response = await axios.get(`https://api.opencagedata.com/geocode/v1/json?q=${latitude}+${longitude}&key=${process.env.REACT_APP_OPENCAGE_API_KEY}`);
                  if (response.data.results && response.data.results.length > 0) {
                     const location = response.data.results[0].formatted;
                     setFormData(prevState => ({
                        ...prevState,
                        location: location,
                        addressCoordinates: coordinates
                     }));
                     toast.success("Location fetched successfully");
                  } else {
                     toast.error("No results found for the current location");
                  }
               } catch (error) {
                  toast.error("Error fetching address information");
               }
            },
            (error) => {
               toast.error("Error getting location");
            }
         );
      } else {
         toast.error("Geolocation is not supported by this browser.");
      }
   };

   const options = allSkills.map((skill) => ({ value: skill.name, label: skill.name }));

   return (
      <div className="container_signin">
         <div className="container_signin_rightt">
            <form onSubmit={handleSubmit} className="signin_form">
               <h2 className='bold'>Task Details</h2>
               <div className='flex_row register_worker_form_div'>
                  <div className="form_input_div">
                     <label htmlFor="requiredSkills">Name of the Task</label>
                     <Select
                        className="select"
                        options={options}
                        onChange={handleSelectChange}
                        value={formData.requiredSkills}
                        placeholder="Search a Worker by Skill..."
                        styles={{
                           menu: (provided) => ({
                              ...provided,
                              overflow: "auto",
                              zIndex: 5,
                              color: "black",
                              backgroundColor: "white",
                           }),
                           control: (provided) => ({
                              ...provided,
                              width: "100%",
                              margin: "auto",
                              color: "black",
                              padding: "none",
                              cursor: "text",
                           }),
                           option: (provided, state) => ({
                              ...provided,
                              color: state.isSelected ? "black" : provided.color,
                              backgroundColor: state.isSelected
                                 ? "white"
                                 : provided.backgroundColor,
                              "&:hover": {
                                 backgroundColor: "lightgray",
                                 color: "black",
                              },
                           }),
                        }}
                     />
                  </div>
                  <div className="form_input_div">
                     <label htmlFor="startDate">Preferred Start Date</label>
                     <input
                        type="date"
                        id="startDate"
                        name="startDate"
                        value={formData.startDate}
                        onChange={handleChange}
                        required
                     />
                  </div>
                  <div className="form_input_div">
                     <label htmlFor="startTime">Preferred Start Time</label>
                     <input
                        type="time"
                        id="startTime"
                        name="startTime"
                        value={formData.startTime}
                        onChange={handleChange}
                        required
                     />
                  </div>
               </div>
               <div className='flex_row register_worker_form_div'>
                  <div className="form_input_div">
                     <label className="required-field">
                        Location <span>*</span>
                        <div className="info-container">
                           <button className='iBtn' type='button'>
                              {otherIcons.infoIcon}
                           </button>
                           <div className="iBtn-description">
                              <p>
                                 Enter your address manually or click the location icon to get your current location.
                              </p>
                           </div>
                        </div>
                     </label>
                     <div className="input-icon">
                        <input
                           type="text"
                           id="location"
                           name="location"
                           value={formData.location}
                           placeholder="Enter address here"
                           onChange={handleChange}
                           onBlur={(e) => handleLocationChange(e.target.value)}
                        />
                        <button
                           type="button"
                           onClick={handleLocationClick}
                           className="location-btn"
                        >
                           <FaLocationCrosshairs />
                        </button>
                     </div>
                  </div>
                  <div className="form_input_div">
                     <label htmlFor="estimatedTime">Estimated Time (hours)</label>
                     <input
                        type="number"
                        id="estimatedTime"
                        name="estimatedTime"
                        value={formData.estimatedTime}
                        onChange={handleNumericChange}
                        min="0"
                        step="0.5"
                        placeholder="Enter estimated hours"
                        required
                     />
                  </div>
                  <div className="form_input_div">
                     <label htmlFor="budget">Budget ($)</label>
                     <input
                        type="number"
                        id="budget"
                        name="budget"
                        value={formData.budget}
                        onChange={handleNumericChange}
                        min="0"
                        step="0.01"
                        placeholder="Enter budget amount"
                        required
                     />
                  </div>
               </div>
               <div className="form_input_div">
                  <label htmlFor="taskDescription">Task Description</label>
                  <textarea
                     id="taskDescription"
                     name="taskDescription"
                     value={formData.taskDescription}
                     style={{ resize: 'vertical' }}
                     rows="6"
                     onChange={handleChange}
                     required
                  />
               </div>
               <div className="button-container">
                  <button type="submit" className="btn submit-btn">
                     Post a Task <Send size={20} />
                  </button>
               </div>
            </form>
         </div>
      </div>
   );
};

export default ClientTaskForm;