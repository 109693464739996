// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.broadcast-task-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
}

.task-card {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 20px;
}

.task-card p {
    margin: 10px 0;
}



.task-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.task-content {
    flex-grow: 1;
}

.task-action {
    align-self: flex-end;
    margin-top: 10px;
}

.broadcast_task_heading {
    text-align: center;
    margin-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/BroadcastTask/BroadcastTask.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,cAAc;AAClB;;;;AAIA;IACI,aAAa;IACb,sBAAsB;IACtB,8BAA8B;AAClC;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,oBAAoB;IACpB,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;AACvB","sourcesContent":[".broadcast-task-container {\n    padding: 20px;\n    max-width: 800px;\n    margin: 0 auto;\n}\n\n.task-card {\n    border: 1px solid #ccc;\n    border-radius: 8px;\n    padding: 15px;\n    margin-bottom: 20px;\n}\n\n.task-card p {\n    margin: 10px 0;\n}\n\n\n\n.task-card {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n}\n\n.task-content {\n    flex-grow: 1;\n}\n\n.task-action {\n    align-self: flex-end;\n    margin-top: 10px;\n}\n\n.broadcast_task_heading {\n    text-align: center;\n    margin-bottom: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
