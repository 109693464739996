import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBroadcastTask, applyForBroadcastTask } from '../../features/Booking';
import './BroadcastTask.css';
import Spinner from '../../Components/Spinner/Spinner';
import toast from 'react-hot-toast';

const BroadcastTask = () => {
    const dispatch = useDispatch();
    const broadcastTasksData = useSelector(state => state.booking.broadcastTasks);
    const user = useSelector(state => state.auth.user);
    const applyStatus = useSelector(state => state.booking.applyStatus);

    console.log('broadcastTasksData:', broadcastTasksData);

    const fetchBroadcastTasks = () => {
        dispatch(getBroadcastTask());
    };

    useEffect(() => {
        fetchBroadcastTasks();
    }, [dispatch]);

    useEffect(() => {
        if (applyStatus === 'succeeded') {
            toast.success('Successfully applied for the task!');
            fetchBroadcastTasks(); // Refetch tasks after successful application
        } else if (applyStatus === 'failed') {
            toast.error('Failed to apply for the task. Please try again.');
        }
    }, [applyStatus]);

    const handleApply = async (broadcastTaskId) => {
        if (user && user.workerId) {
            try {
                const result = await dispatch(applyForBroadcastTask({
                    broadcastTaskId: broadcastTaskId,
                    workerId: user.workerId
                })).unwrap();
                if (result.success) {
                    toast.success('Successfully applied for the task!');
                    fetchBroadcastTasks(); // Refetch tasks after successful application
                } else {
                    toast.error('Failed to apply for the task. Please try again.');
                }
            } catch (error) {
                toast.error('An error occurred while applying for the task.');
            }
        } else {
            toast.error('User or workerId not available');
        }
    };

    const formatStartTime = (timeString) => {
        if (!timeString || typeof timeString !== 'string') {
            return 'Invalid Time';
        }
        return timeString;
    };

    const hasUserApplied = (task) => {
        return task.applications.some(application => application.workerId === user.workerId);
    };

    const broadcastTasks = broadcastTasksData && broadcastTasksData.broadcastTasks;

    if (!broadcastTasks) {
        return <div className="broadcast-task-container"><Spinner /></div>;
    }

    if (!Array.isArray(broadcastTasks)) {
        console.error('broadcastTasks is not an array:', broadcastTasks);
        return <div className="broadcast-task-container">Error loading tasks. Please try again later.</div>;
    }

    return (
        <div className="broadcast-task-container">
            <h2 className='broadcast_task_heading bold '>Broadcast Tasks</h2>
            {broadcastTasks.length === 0 ? (
                <p>No tasks available.</p>
            ) : (
                broadcastTasks.map(task => (
                    <div key={task._id} className="task-card box_shadow">
                        <div className="task-content">
                            <h3>{task.taskName}</h3>
                            <p><strong>Description:</strong> {task.taskDescription}</p>
                            <p><strong>Location:</strong> {task.taskLocation.textAddress}</p>
                            <p><strong>Start Time:</strong> {formatStartTime(task.startTime)}</p>
                            <p><strong>Estimated Time:</strong> {task.estimatedTime} hours</p>
                            <p><strong>Status:</strong> {task.taskStatus}</p>
                        </div>
                        <div className="task-action">
                            {hasUserApplied(task) ? (
                                <p className="applied-message">You have already applied for this task</p>
                            ) : (
                                <button
                                    onClick={() => handleApply(task._id)}
                                    className="btn apply-button"
                                >
                                    Apply
                                </button>
                            )}
                        </div>
                    </div>
                ))
            )}
        </div>
    );
}

export default BroadcastTask;