import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
// import { toast } from "react-hot-toast";

const initialState = {
   isError: false,
   isSuccess: false,
   isLoading: false,
   employerBookings: '',
   workerBookings: '',
   broadcastTasks: '',
   message: "",
};

//////////////////////Booking Worker///////////////////////////
export const BookWorker = createAsyncThunk(
   "/auth/bookWorker",
   async (data, thunkAPI) => {
      try {
         const token = thunkAPI.getState().auth.user.token
         const config = {
            headers: {
               Authorization: `Bearer ${token}`
            }
         }

         const API_URL = `${process.env.REACT_APP_SERVER_URL}/auth/bookWorker`;
         const response = await axios.post(API_URL, data, config);
         return response.data;
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.response.data.status ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

//////////////////////Get Bookings by Worker ID///////////////////////////
export const getBookingsByWorkerID = createAsyncThunk(
   "/auth/getBookingsByWorkerID",
   async (workerId, thunkAPI) => {
      try {
         const token = thunkAPI.getState().auth.user.token
         const config = {
            headers: {
               Authorization: `Bearer ${token}`
            }
         }
         const API_URL = `${process.env.REACT_APP_SERVER_URL}/auth/getBookingsByWorkerId/${workerId}`;
         const response = await axios.get(API_URL, config);
         return response.data;
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.response.data.status ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);


//////////////////////Get Employer Bookings///////////////////////////
export const getEmployerBookings = createAsyncThunk(
   "/auth/getEmployerBookings",
   async (_, thunkAPI) => {
      try {
         const token = thunkAPI.getState().auth.user.token
         const config = {
            headers: {
               Authorization: `Bearer ${token}`
            }
         }

         const API_URL = `${process.env.REACT_APP_SERVER_URL}/auth/getEmployerBookings`;
         const response = await axios.get(API_URL, config);
         console.log(response.data, 'response.data');
         return response.data;
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.response.data.status ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

//Accept Booking By Worker
export const acceptBookingByWorker = createAsyncThunk(
   "/auth/acceptBookingByWorker",
   async (data, thunkAPI) => {
      try {
         const token = thunkAPI.getState().auth.user.token
         const config = {
            headers: {
               Authorization: `Bearer ${token}`
            }
         }

         const API_URL = `${process.env.REACT_APP_SERVER_URL}/auth/acceptBookingByWorker`;
         const response = await axios.put(API_URL, data, config);
         console.log(response);
         return response.data;
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.response.data.status ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

//Reject Booking By Employer
export const rejectBookingByWorker = createAsyncThunk(
   "/auth/rejectBookingByWorker",
   async (data, thunkAPI) => {
      try {
         const token = thunkAPI.getState().auth.user.token
         const config = {
            headers: {
               Authorization: `Bearer ${token}`
            }
         }

         const API_URL = `${process.env.REACT_APP_SERVER_URL}/auth/rejectBookingByWorker`;
         const response = await axios.put(API_URL, data, config);
         return response.data;
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.response.data.status ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

//updateBookingStatusByWorker
export const updateBookingStatusByWorker = createAsyncThunk(
   "/auth/acceptBookingByEmployer",
   async (data, thunkAPI) => {
      try {
         const token = thunkAPI.getState().auth.user.token
         const config = {
            headers: {
               Authorization: `Bearer ${token}`
            }
         }

         const API_URL = `${process.env.REACT_APP_SERVER_URL}/auth/updateBookingStatusByWorker`;
         const response = await axios.patch(API_URL, data, config);
         return response.data;
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.response.data.status ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

//Create Broadcast Job

export const createBroadcastJob = createAsyncThunk(
   "/auth/createBroadcastJob",
   async (data, thunkAPI) => {
      try {
         const token = thunkAPI.getState().auth.user.token
         const config = {
            headers: {
               Authorization: `Bearer ${token}`
            }
         }
         const API_URL = `${process.env.REACT_APP_SERVER_URL}/auth/createBroadcastTask`;
         const response = await axios.post(API_URL, data, config);
         return response.data;
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.response.data.status ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

//get All Broadcast Task
export const getBroadcastTask = createAsyncThunk(
   "/auth/getBroadcastTask",
   async (_, thunkAPI) => {
      try {
         const token = thunkAPI.getState().auth.user.token
         const config = {
            headers: {
               Authorization: `Bearer ${token}`
            }

         }
         const API_URL = `${process.env.REACT_APP_SERVER_URL}/auth/getAllBroadcastTasks`;
         const response = await axios.get(API_URL, config);

         return response.data;
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.response.data.status ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

//Apply for Broadcast Task
export const applyForBroadcastTask = createAsyncThunk(
   "/auth/applyForBroadcastTask",
   async (data, thunkAPI) => {
      try {
         const token = thunkAPI.getState().auth.user.token
         const config = {
            headers: {
               Authorization: `Bearer ${token}`
            }
         }

         const API_URL = `${process.env.REACT_APP_SERVER_URL}/auth/applyForBroadcastTask`;
         const response = await axios.post(API_URL, data, config);
         return response.data;
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.response.data.status ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);



export const bookingSlice = createSlice({
   name: "booking",
   initialState,
   reducers: {
      clearState: (state) => {
         state.isError = false;
         state.isSuccess = false;
         state.isLoading = false;
         state.message = "";
      },
   },
   extraReducers: (builder) => {
      builder
         .addCase(BookWorker.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(BookWorker.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.message = payload.message;
         })
         .addCase(BookWorker.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.isError = true;
            state.message = payload;
         })
         .addCase(getBookingsByWorkerID.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getBookingsByWorkerID.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.message = payload.message;
            state.workerBookings = payload;
         })
         .addCase(getBookingsByWorkerID.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.isError = true;
            state.message = payload;
         })
         .addCase(getEmployerBookings.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getEmployerBookings.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.message = payload.message;
            state.employerBookings = payload;
         })
         .addCase(getEmployerBookings.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.isError = true;
            state.message = payload;
         })
         .addCase(acceptBookingByWorker.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(acceptBookingByWorker.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.message = payload.message;
         })
         .addCase(acceptBookingByWorker.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.isError = true;
            state.message = payload;
         })
         .addCase(rejectBookingByWorker.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(rejectBookingByWorker.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.message = payload.message;
         })
         .addCase(rejectBookingByWorker.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.isError = true;
            state.message = payload;
         })
         .addCase(updateBookingStatusByWorker.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(updateBookingStatusByWorker.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.message = payload.message;
         })
         .addCase(updateBookingStatusByWorker.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.isError = true;
            state.message = payload;
         })
         .addCase(createBroadcastJob.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(createBroadcastJob.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.message = payload.message;
         })
         .addCase(createBroadcastJob.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.isError = true;
            state.message = payload;
         }
         )
         .addCase(getBroadcastTask.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getBroadcastTask.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.message = payload.message;
            state.broadcastTasks = payload;
         })
         .addCase(getBroadcastTask.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.isError = true;
            state.message = payload;
         })
         .addCase(applyForBroadcastTask.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(applyForBroadcastTask.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.message = payload.message;
         })
         .addCase(applyForBroadcastTask.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.isError = true;
            state.message = payload;
         });

   }
});

export const { clearState } = bookingSlice.actions;

export default bookingSlice.reducer;